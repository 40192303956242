<template>
	<div class="case-mobile" v-if="isMobile">
		<div class="banner posRelative">
			<div class="default-banner posAbsoluteAll" v-if="!banner.type"></div>
			<img :src="banner.url" class="posAbsoluteAll" draggable="false" v-if="banner.type===1">
			<video ref="bannerVideo" class="posAbsoluteAll video-div" :src="banner.url" :autoplay="true" loop="loop"
				:muted="isMuted" v-if="banner.type===2"></video>
			<div class="column-me title-text" :class="{'title-text-anim':showBannerAnim}" v-show="showBannerAnim">
				<span>{{banner.title}}</span>
				<span class="margin-top40">{{banner.sub_title}}</span>
			</div>
			<div v-if="banner.type===2">
				<img src="../assets/img/laba2.png" class="img-laba anim-bigger-small" v-if="isMuted"
					@click.stop="isMuted=false">
				<img src="../assets/img/laba1.png" class="img-laba anim-bigger-small" v-else @click.stop="isMuted=true">
			</div>
		</div>
		<div class="main-content" v-if="detailItem">
			<div class="title-text" v-if="language==='0'">投资案例</div>
			<div class="title-text" v-if="language==='1'">Portfolio</div>
			<div class="title-line"></div>
			<div class="introduce" v-html="detailItem.introduce"></div>
			<div class="margin-top50 row-me flex-wrap">
				<div class="item-div column-me" v-for="(item,index) in detailItem.list" :key="index"
					@click="clickItem(item)">
					<div class="top-div row-me center-all flex1">
						<el-image :src="item.portfolios_cover" fit="scale-down" class="img" hide-on-click-modal>
						</el-image>
					</div>
					<div class="bottom-div">{{item.portfolios_name}}</div>
				</div>
			</div>
		</div>
	</div>

	<div class="case" v-else>
		<div class="banner posRelative">
			<div class="default-banner posAbsoluteAll" v-if="!banner.type"></div>
			<img :src="banner.url" class="posAbsoluteAll" draggable="false" v-if="banner.type===1">
			<video ref="bannerVideo" class="posAbsoluteAll video-div" :src="banner.url" :autoplay="true" loop="loop"
				:muted="isMuted" v-if="banner.type===2"></video>
			<div class="column-me title-text" :class="{'title-text-anim':showBannerAnim}" v-show="showBannerAnim">
				<span>{{banner.title}}</span>
				<span class="margin-top40">{{banner.sub_title}}</span>
			</div>
			<div v-if="banner.type===2">
				<img src="../assets/img/laba2.png" class="img-laba anim-bigger-small" v-if="isMuted"
					@click.stop="isMuted=false">
				<img src="../assets/img/laba1.png" class="img-laba anim-bigger-small" v-else @click.stop="isMuted=true">
			</div>
		</div>
		<div class="main-content" v-if="detailItem">
			<div class="title-text" v-if="language==='0'">投资案例</div>
			<div class="title-text" v-if="language==='1'">Portfolio</div>
			<div class="title-line"></div>
			<div class="introduce" v-html="detailItem.introduce"></div>
			<div class="margin-top100 row-me flex-wrap">
				<div class="item-div column-me" v-for="(item,index) in detailItem.list" :key="index"
					@click="clickItem(item)">
					<div class="top-div row-me center-all">
						<el-image :src="item.portfolios_cover" fit="scale-down" class="img" hide-on-click-modal>
						</el-image>
					</div>
					<div class="bottom-div flex1">{{item.portfolios_name}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		components: {

		},
		data() {
			return {
				banner: {},
				showBannerAnim: false,
				detailItem: null,
				language: '', //0中文，1英文
				isMuted: true, //视频是否静音
				isMobile: '', //0电脑，1手机
			}
		},
		watch: {
			navItem: {
				handler: function(newVal, oldVal) {
					this.getBanner()
					this.getData()
				}
			}
		},
		computed: {
			navItem() {
				return this.$store.state.navItem;
			},
		},
		created() {
			this.isMobile = sessionStorage.getItem("mobile") === '1'
			this.language = localStorage.getItem("language")
			document.title = this.navItem.name
			setTimeout(() => {
				this.showBannerAnim = true
			}, 1000)
			this.getBanner()
			this.getData()
		},
		activated() {
			this.language = localStorage.getItem("language")
			document.title = this.navItem.name
			this.showBannerAnim = false
			setTimeout(() => {
				this.showBannerAnim = true
			}, 1000)
		},
		mounted() {

		},
		methods: {
			clickItem(item) {
				window.open(item.link_url)
			},
			getBanner() {
				this.banner = {}
				let link_url = this.$store.state.navItem.link_url
				if (link_url == '/case') {
					this.$postRequest('/web/navInfo', {
						id: this.$store.state.navItem.id
					}).then((res) => {
						if (res) {
							this.banner = res.data
						}
					}).catch((error) => {
						console.log(error)
					})
				}
			},
			//获取数据
			getData() {
				this.$postRequest('/web/portfolios', {}).then((res) => {
					if (res) {
						this.detailItem = res.data
					}
				}).catch((error) => {
					console.log(error)
				})
			},
		},
	}
</script>

<style lang="scss" scoped>
	.case-mobile {
		background-color: #F5F5F7;

		.banner {
			width: 100%;
			height: 35vh;

			.video-div {
				object-fit: cover;
			}

			.title-text {
				font-size: 25px;
				color: #FFFFFF;
				position: absolute;
				left: 20px;
				top: 30%;
				-ms-user-select: none;
				-webkit-user-select: none;
			}
		}

		.main-content {
			padding: 20px;

			.introduce {
				font-size: 18px;
				color: #000000;
				line-height: 32px;
				margin-top: 25px;
			}

			.item-div {
				width: 47%;
				margin-bottom: 20px;
				margin-right: 20px;

				&:nth-child(2n) {
					margin-right: 0;
				}

				.top-div {
					width: 100%;
					height: 130px;
					background-color: #FFFFFF;
				}

				.img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}

				.bottom-div {
					width: 100%;
					text-align: center;
					background-color: #1D9E9B;
					font-size: 18px;
					color: #FFFFFF;
					padding: 10px 0;
				}
			}
		}

	}

	.case {
		background-color: #F5F5F7;

		.banner {
			width: 100%;
			height: 80vh;

			.video-div {
				object-fit: cover;
			}

			.title-text {
				font-size: 80px;
				color: #FFFFFF;
				position: absolute;
				left: 278px;
				top: 40%;
				-ms-user-select: none;
				-webkit-user-select: none;
			}
		}

		.main-content {
			padding: 100px 278px;

			.introduce {
				font-size: 18px;
				color: #000000;
				line-height: 32px;
				margin-top: 25px;
			}

			.item-div {
				width: 302px;
				margin-bottom: 60px;
				margin-right: 52px;
				cursor: pointer;

				&:nth-child(4n) {
					margin-right: 0;
				}

				.top-div {
					width: 100%;
					height: 200px;
					background-color: #FFFFFF;
				}

				.img {
					width: 90%;
					height: 160px;
					object-fit: scale-down;

					&:hover {
						width: 100%;
						height: 180px;
						object-fit: cover;
					}
				}

				.bottom-div {
					width: 100%;
					text-align: center;
					background-color: #1D9E9B;
					font-size: 30px;
					color: #FFFFFF;
					padding: 10px 0;
				}
			}
		}

	}
</style>
